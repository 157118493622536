console.log("BEGIN: beagle.js");

require('script-loader!jquery/dist/jquery')
require('script-loader!jquery-ui/jquery-ui.min.js')
require('script-loader!bootstrap/dist/js/bootstrap.bundle')
require('script-loader!jszip/dist/jszip.min.js')
require('script-loader!pdfmake/build/pdfmake.min.js')
require('script-loader!pdfmake/build/vfs_fonts.js')
require('script-loader!perfect-scrollbar/dist/js/perfect-scrollbar.jquery')
require('script-loader!Sparkline/dist/jquery.sparkline.min.js')
require('script-loader!chart.js/dist/Chart.min.js')
require('script-loader!select2/dist/js/select2.min.js')
require('script-loader!summernote/dist/summernote-bs4.js')
require('script-loader!moment/min/moment.min.js')
require('script-loader!bootstrap-datetime-picker/js/bootstrap-datetimepicker.min.js')
require('script-loader!jquery.maskedinput/src/jquery.maskedinput.js')
require('script-loader!fullcalendar/dist/fullcalendar.min.js')
require('script-loader!datatables.net/js/jquery.dataTables.js')
require('script-loader!datatables.net-bs4/js/dataTables.bootstrap4.js')
require('script-loader!datatables.net-buttons/js/dataTables.buttons.min.js')
require('script-loader!datatables.net-buttons/js/buttons.html5.min.js')
// require('script-loader!datatables.net-buttons/js/buttons.flash.min.js')
require('script-loader!datatables.net-buttons/js/buttons.print.min.js')
require('script-loader!datatables.net-buttons/js/buttons.colVis.min.js')
require('script-loader!datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js')
require('script-loader!datatables.net-responsive/js/dataTables.responsive.min')
require('script-loader!Gritter/js/jquery.gritter.js');
require('script-loader!jquery-timepicker/jquery.timepicker.js');
require('script-loader!magnific-popup/dist/jquery.magnific-popup');

// add jQuery .beforeOn() to complement .on()
// registers an event handler to run before all others
// assists in building workarounds for third party plugin behaviors
$.fn.beforeOn = function (type, fn) {
    this.each(function () {
        $(this).on(type, fn)
        const bindings = $._data(this, 'events')[type]
        bindings.unshift(bindings.pop())
    })
    return this
}

console.log("END: beagle.js");
